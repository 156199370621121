import React, { Component } from "react";
import computationalaestheticsthumb from "./computationalaesthetics.svg";
 
class ComputationalAestheticsAbstract extends Component {
  render() {
    return (
      <div className="copy">
        <h2>Computational Aesthetics</h2>
        <img src={computationalaestheticsthumb} className="colorgram" alt="computational aesthetics"/>
        A beginning is the time for taking the most delicate care that the balances are 
        correct. This every sister of the Bene Gesserit knows. To begin your study of 
        the life of Muad'Dib, then, take care that you first place him in his time: born 
        in the 57th year of the Padishah Emperor, Shaddam IV. And take the most special 
        care that you locate Muad'Dib in his place: the Planet Arrakis. Do not be 
        deceived by the fact that he was born on Caladan and lived his first fifteen 
        years there. Arrakis, the planet known as Dune, is forever his place, 
        -from "Manual of Muad'Dib" by the Princess Irulan 
        <br/>
        <br/>
        In the week before their departure to Arrakis, when all the final scurrying 
        about had reached a nearly unbearable frenzy, an old crone came to visit the 
        mother of the boy, Paul. 
        <br/>
        <br/>
        It was a warm night at Castle Caladan, and the ancient pile of stone that 
        had served the Atreides family as home for twenty-six generations bore that 
        cooled-sweat feeling it acquired before a change in the weather. 
      </div>
    );
  }
}

class ComputationalAesthetics extends Component {
  render() {
    return (
      <div className="content">
      <br/>
      <ComputationalAestheticsAbstract/>
      <div className="copy">
      even more copy about this topic
      </div>
      </div>
    );
  }
}
 
export default ComputationalAesthetics;
export { ComputationalAestheticsAbstract };
