import React, { Component } from "react";
import colortheorythumb from "./colortheory.svg";
 
class ColorTheoryAbstract extends Component {
  render() {
    return (
      <div className="copy">
        <h2>Color Theory</h2>
        <img className="colorgram" src={colortheorythumb} alt="color theory"/>
          Where the dunes began, perhaps fifty meters away at the foot of a rock 
          beach, a silver-gray curve broached from the desert, sending rivers of sand and 
          dust cascading all around. It lifted higher, resolved into a giant, questing 
          mouth. It was a round, black hole with edges glistening in the moonlight. 
          <br/>
          <br/>
          The mouth snaked toward the narrow crack where Paul and Jessica huddled. 
          Cinnamon yelled in their nostrils. Moonlight flashed from crystal teeth. 
          <br/>
          <br/>
          Back and forth the great mouth wove. 
          <br/>
          <br/>
          <br/>
          <br/>
          Paul stilled his breathing. 
          Jessica crouched staring. 
          <br/>
          <br/>
          It took intense concentration of her Bene Gesserit training to put down the 
          primal terrors, subduing a race-memory fear that threatened to fill her mind. 
          <br/>
          <br/>
          Paul felt a kind of elation. In some recent instant, he had crossed a time 
          barrier into more unknown territory. He could sense the darkness ahead, nothing 
          revealed to his inner eye. It was as though some step he had taken had plunged 
          him into a well ... or into the trough of a wave where the future was 
          invisible. The landscape had undergone a profound shifting. 
      </div>
    );
  }
}

class ColorTheory extends Component {
  render() {
    return (
      <div className="content">
      <br/>
      <ColorTheoryAbstract/>
      <div className="copy">
      even more copy about this topic
      </div>
      </div>
    );
  }
}
 
export default ColorTheory;
export { ColorTheoryAbstract };
