import React, { Component } from "react";
import { NavLink} from "react-router-dom";
import ColorTheory, { ColorTheoryAbstract } from "./ColorTheory";
import CollectiveCognition, { CollectiveCognitionAbstract } from "./CollectiveCognition";
import AlgorithmicBias, { AlgorithmicBiasAbstract } from "./AlgorithmicBias";
import ComputationalAesthetics, { ComputationalAestheticsAbstract } from "./ComputationalAesthetics";
import "./App.css";

 
class Publications extends Component {
  render() {
    return (
      <div>
        <div className="content">
          <div className="copy">
            <h2>Code</h2>
            <p><a href="https://github.com/comp-syn/comp-syn" target="_blank">comp-syn</a> is a python package which provides a novel methodology to explore relationships between abstract concepts and color. We provide functionalities to create a dataset for word - image associations through Google Cloud and WordNet, and demonstrate a variety of image and word related analyses. We introduce constructs such as Colorgrams as a tool for analysis, as well as the ability to create color representational vectors for images, and words as aggregations of images.</p>
          </div>
          <div className="copy">
          </div>
        </div>
      </div>
    )
  }
}

export default Publications;
