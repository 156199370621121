import React, { Component } from "react";
import algorithmicbiasthumb from "./algorithmicbias.svg";
 
class AlgorithmicBiasAbstract extends Component {
  render() {
    return (
      <div className="copy">
        <h2>Algorithmic Bias</h2>
        <img src={algorithmicbiasthumb} className="colorgram" alt="algorithmic bias"/>
        "Read it aloud," Yueh said. 
        <br/>
        <br/>
        <br/>
        <br/>
        Paul wet his lips with his tongue, read: "Think you of the fact that a deaf 
        person cannot hear. Then, what deafness may we not all possess? What senses do 
        we lack that we cannot see and cannot hear another world all around us? What is 
        there around us that we cannot — " 
        <br/>
        <br/>
        <br/>
        <br/>
        "Stop it!" Yueh barked.
        <br/>
        <br/>
              </div>
    );
  }
}

class AlgorithmicBias extends Component {
  render() {
    return (
      <div className="content">
      <AlgorithmicBiasAbstract/>
      <div className="copy">
      even more copy about this topic
      </div>
      </div>
    );
  }
}
 
export default AlgorithmicBias;
export { AlgorithmicBiasAbstract };
