import React from 'react';
import { NavLink } from "react-router-dom";

import { Markup } from "interweave";
import ImageGallery from 'react-image-gallery';
import Modal from '@material-ui/core/Modal';

import portfolio1 from "./portfolio1.jpeg";
import portfolio2 from "./portfolio2.jpeg";
import portfolio3 from "./portfolio3.jpeg";
import portfolio4 from "./portfolio4.jpeg";
import portfolio5 from "./portfolio5.jpeg";
import portfolio6 from "./portfolio6.jpeg";

import langipA from "./serene.png";
import langipB from "./restfulness.png";
import langipC from "./pleasant.png";

import taste1 from "./sweet.png";
import taste2 from "./sour.png";
import taste3 from "./salty.png";
import taste4 from "./umami.png";
import taste5 from "./bitter.png";

import dickinson from "./dickinson.jpg";
import shakespeare from "./shakespeare.jpg";

import artofscience from "./artofscience.jpg";

import flowers from "./flowers.png";

import mamenglish from "./milleniaasmomentenglish.jpg";
import mamitalian from "./milleniaasmomentitalian.jpg";
import mamlegendenglish from "./mamlegendenglishcrop.mp4";
import mamlegenditalian from "./mamlegenditaliancrop.mp4";
import mamarticle from "./Millenia\ As\ Moment:\ A\ Triptych\ in\ 75\ Colorgrams.pdf";


class NiceGallery extends React.Component {

  constructor() {
    super();
    this.handleResize = this.handleResize.bind(this);
    this._modalClose = this._modalClose.bind(this);
    this.state = {
      showIndex: true,
      showBullets: false,
      infinite: true,
      showThumbnails: false,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: false,
      showGalleryPlayButton: false,
      showNav: true,
      autoPlay: false,
      isRTL: false,
      slideDuration: 0,
      slideInterval: 10000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'bottom',
      showVideo: {},
      useWindowKeyDown: true,
      windowWidth: window.innerWidth,
      modalOpen: false,
      description: null // Optional description which will appear as a modal on click
    };
    if (this.state.windowWidth < 640) {
      // swiping is intuitive on smaller screens
      this.setState({showNav: false});
    }
  }

  handleResize(e) {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentWillUnmount(){
    document.getElementById("portal").style.display = "block";
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    document.getElementById("portal").style.display = "none";
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.slideInterval !== prevState.slideInterval ||
        this.state.slideDuration !== prevState.slideDuration) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
  }

  _onImageClick(event) {
    if (this.state.description !== null && this.state.description.length > 0 ) {
      // only toggle modal if there is content for it
      this.setState({modalOpen: true})
    }

  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    this.setState({[state]: event.target.value});
  }

  _handleCheckboxChange(state, event) {
    this.setState({[state]: event.target.checked});
  }

  _handleThumbnailPositionChange(event) {
    this.setState({thumbnailPosition: event.target.value});
  }
  _modalClose() {
    this.setState({modalOpen: false });
  }

  render() {
    return (
      <div>
      <section className='app'>
        <div className="floattop"><NavLink to="/gallery">←</NavLink></div>
        <br/>
        <br/>
        <Modal
          open={this.state.modalOpen}
          onClose={this._modalClose}
        >
          <div className="papermodal">
            <Markup content={this.state.description}/>
          </div>
        </Modal>
        <ImageGallery
          ref={i => this._imageGallery = i}
          items={this.images}
          lazyLoad={false}
          onClick={this._onImageClick.bind(this)}
          onImageLoad={this._onImageLoad}
          onSlide={this._onSlide.bind(this)}
          onPause={this._onPause.bind(this)}
          onScreenChange={this._onScreenChange.bind(this)}
          onPlay={this._onPlay.bind(this)}
          infinite={this.state.infinite}
          showBullets={this.state.showBullets}
          showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
          showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
          showThumbnails={this.state.showThumbnails}
          showIndex={this.state.showIndex}
          showNav={this.state.showNav}
          autoPlay={this.state.autoPlay}
          isRTL={this.state.isRTL}
          thumbnailPosition={this.state.thumbnailPosition}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          slideOnThumbnailOver={this.state.slideOnThumbnailOver}
          additionalClass="app-image-gallery"
          useWindowKeyDown={this.state.useWindowKeyDown}
        />

        <div className='app-sandbox'>

          <div className='app-sandbox-content'>
          </div>

        </div>
      </section>
      </div>
    );
  }
}

class GalleryPortfolio extends NiceGallery {
  constructor() {
    super();
    this.images = [
      {
        original: {portfolio1}.portfolio1,
        thumbnail: {portfolio1}.portfolio1,
      },{
        original: {portfolio2}.portfolio2,
        thumbnail: {portfolio2}.portfolio2,
      },{
        original: {portfolio3}.portfolio3,
        thumbnail: {portfolio3}.portfolio3,
      },{
        original: {portfolio4}.portfolio4,
        thumbnail: {portfolio4}.portfolio4,
      },{
        original: {portfolio5}.portfolio5,
        thumbnail: {portfolio5}.portfolio5,
      },{
        original: {portfolio6}.portfolio6,
        thumbnail: {portfolio6}.portfolio6,
      }
    ]
  }
}


class GalleryThreeLangips extends NiceGallery {
  constructor() {
    super();
    this.images = [
      {
        original: {langipA}.langipA,
        thumbnail: {langipA}.langipA,
        description: "Serene",
      },{
        original: {langipB}.langipB,
        thumbnail: {langipB}.langipB,
        description: "Restfulness",
      },{
        original: {langipC}.langipC,
        thumbnail: {langipC}.langipC,
        description: "Pleasant"
      }
    ]
  }
}

class GalleryFiveTastes extends NiceGallery {
  constructor() {
    super();
    this.images = [
      {
        original: {taste1}.taste1,
        thumbnail:  {taste1}.taste1,
        description: "Sweet",
      },{
        original: {taste2}.taste2,
        thumbnail:  {taste2}.taste2,
        description: "Sour",
      },{
        original: {taste3}.taste3,
        thumbnail:  {taste3}.taste3,
        description: "Salty",
      },{
        original: {taste4}.taste4,
        thumbnail:  {taste4}.taste4,
        description: "Umami",
      },{
        original: {taste5}.taste5,
        thumbnail:  {taste5}.taste5,
        description: "Bitter",
      }
    ]
  }
}

class GalleryDickinsonThenShakespeare extends NiceGallery {
  constructor() {
    super();
    this.images = [
      { 
        original: {dickinson}.dickinson,
        thumbnail: {dickinson}.dickinson,
        description: "Emily Dickinson"
      },{
        original: {shakespeare}.shakespeare,
        thumbnail: {shakespeare}.shakespeare,
        description: "William Shakespeare"
      }
    ]
  }
}

class GalleryArtOfScience2020 extends NiceGallery {
  constructor() {
    super();
    this.images = [
      {
        original: {artofscience}.artofscience,
        thumbnail: {artofscience}.artofscience
      }
    ];
    this.state = {
      showThumbnails: false,
      showPlayButton: false,
      showFullscreenButton: false,
      description: `
          <div className="copy info-modal">
          <h2>Changing Views in Data Science over Fifty Years.</h2>
          <p>
            Our research team has developed a multimodal natural language processing algorithm: Computational Synesthesia (comp-syn). In work published by Cognition, we showed that Google Image results associated with abstract words exhibit strikingly coherent color distributions. For example, the word "physics" is strongly associated with blueish hues. Remarkably, semantically similar words (such as "physics" and "computer science") exhibit similar color distributions when analyzed in a colorspace that emulates human perception.<br/><br/>From top to bottom, comp-syn presents: <mark>neural network</mark>, <mark>natural language processing</mark>, <mark>computer vision</mark>, <mark>machine learning</mark>, and <mark>artificial intelligence</mark>.<br/><br/>Each unit, or colorgram, displays the perceptually uniform average of 100 Google Image search results. From left to right, each colorgram is composed of images modified before a certain date, starting in 1970 and ending today, with time spaced logarithmically. In other words, this work depicts how our contemporary understanding of data science has emerged.
          </p>
          </div>`
    }
  }
}

class GalleryFlowers extends NiceGallery {
  constructor() {
    super();
    this.images = [
      {
        original: {flowers}.flowers,
        thumbnail: {flowers}.flowers
      }
    ];
    this.state = {
      showThumbnails: false,
      showPlayButton: false,
      showFullscreenButton: false,
      description: null
    }
  }
}

class GalleryMilleniaAsMoment extends NiceGallery {
  constructor() {
    super();
    this.images = [
      {
        original: {mamenglish}.mamenglish,
        thumbnail: {mamenglish}.mamenglish,
        legend: {mamlegendenglish}.mamlegendenglish,
        renderItem: this._renderLegend.bind(this)
      },
      {
        original: {mamitalian}.mamitalian,
        thumbnail: {mamitalian}.mamitalian,
        legend: {mamlegenditalian}.mamlegenditalian,
        renderItem: this._renderLegend.bind(this)
      }
    ];
    this.state = {
      showThumbnails: false,
      showPlayButton: true,
      showFullscreenButton: true,
      description: null,
      autoPlay: false,
      slideInterval: 10000,
      slideDuration: 0,
      article: {mamarticle}.mamarticle
    }
  }

  _renderPdf(item) {
    return (
      <div>
        <embed src={item.original} width="100%" height="700px" />
      </div>
    )
  }

  _renderLegend(item) {
    return (
      <div>
        <img alt="mam" className="image-gallery-image" src={item.original}/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <video className="widevideo" autoPlay muted loop playsInline>
        <source src={item.legend} type="video/mp4"/>
        </video>
        <br/>
        <br/>
      </div>
    )
  }

  render() {
    const elementsTree = super.render();
    return (
      <div>
      {elementsTree}
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="copy">
        <a href={this.state.article} download>
          Creativity & Cognition 2021 Comp-syn Conference Paper: Millenia as Moment Textual Appendix
        </a>
      </div>
      </div>
    )
    //return (
    //  <div>
    //    {elementsTree}
    //  <div>
    //)
  }
  
}

export { 
  GalleryPortfolio, 
  GalleryThreeLangips, 
  GalleryFiveTastes, 
  GalleryDickinsonThenShakespeare, 
  GalleryArtOfScience2020,
  GalleryFlowers,
  GalleryMilleniaAsMoment
};

export default NiceGallery;
