import React, { Component } from "react";
import { NavLink} from "react-router-dom";
import ColorTheory, { ColorTheoryAbstract } from "./ColorTheory";
import CollectiveCognition, { CollectiveCognitionAbstract } from "./CollectiveCognition";
import AlgorithmicBias, { AlgorithmicBiasAbstract } from "./AlgorithmicBias";
import ComputationalAesthetics, { ComputationalAestheticsAbstract } from "./ComputationalAesthetics";
import "./App.css";

 
class Publications extends Component {
  render() {
    return (
      <div>
        <div className="content">
          <div className="copy">
            <h2>Publications</h2>
            <a href="https://doi.org/10.1038/s41586-024-07068-x" target="_blank">Douglas Guilbealt, Solène Delecourt, Tasker Hull, Bhargav Srinivasa Desikan, Mark Chu, Ethan Nadler. 2024. <mark>Online images amplify gender bias.</mark> Nature.</a>
            <br/>
            <br/>
            <a href="https://doi.org/10.1016/j.cognition.2023.105621" target="_blank">Ethan O. Nadler, Elise Darragh-Ford, Bhargav Srinivasa Desikan, Christian Conaway, Mark Chu, Tasker Hull, Douglas Guilbeault. 2023. <mark> Divergences in color perception between deep neural networks and humans </mark> Cognition.</a>
            <br/>
            <br/>
            <a href="https://doi.org/10.18653/v1/2022.acl-long.492" target="_blank">Mark Chu, Bhargav Srinivasa Desikan, Ethan Nadler, Donald Ruggiero Lo Sardo, Elise Darragh-Ford, Douglas Guilbeault. 2022. <mark> Signal in Noise: Exploring Meaning Encoded in Random Character Sequences with Character-Aware Language Models.</mark> Proceedings of the 60th Annual Meeting of the Association for Computational Linguistics (Volume 1: Long Papers).</a>
            <br/>
            <br/>
            <a href="https://doi.org/10.1145/3450741.3466848" target="_blank">Mark Chu, Donald Ruggiero Lo Sardo, and Douglas Guilbeault. 2021. <mark>Millenia as Moment: A Triptych in 75 Colorgrams by Comp-syn.</mark> ACM C&C’21: Creativity and Cognition.</a>
            <br/>
            <br/>
            <a href="https://doi.org/10.18653/v1/2020.coling-main.154" target="_blank">Bhargav Srinivasa Desikan, Tasker Hull, Ethan O. Nadler, Douglas Guilbeault, Aabir Abubaker Kar, Mark Chu, and Donald Ruggiero Lo Sardoi. 2020. <mark>comp-syn: Perceptually Grounded Word Embeddings with Color.</mark> COLING, 2020.</a> 
            <br/>
            <br/>
            <a href="https://doi.org/10.1016/j.cognition.2020.104306" target="_blank">Douglas Guilbeault,  Ethan O Nadler,  Mark Chu,  Donald Ruggiero Lo Sardo,  Aabir Abubaker Kar,  and Bhar-gav Srinivasa Desikan. 2020. <mark>Color associations in abstract semantic domains.</mark> Cognition.</a> 
          </div>
        </div>
      </div>
    )
  }
}

export default Publications;
