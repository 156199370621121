import React, { Component } from "react";
//import { NavLink} from "react-router-dom";
import { Markup } from "interweave";

import TitleAbstractBody from "./TitleAbstractBody";
import { ResearchSummaries, MissionStatement} from "./Research/Copy";

class Research extends Component {
  render() {
    return (
      <div>
        <div className="content">
          <div className="copy">
          <Markup content={MissionStatement}/>
          </div>
          <br/>
          {ResearchSummaries.map((tab, index) => {
            return ( 
              <div>
                <div className="copy">
                  <TitleAbstractBody key={index} Title={tab["Title"]} Abstract={tab["Abstract"]} Body={tab["Body"]}/>
                </div>
                <br/>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

export default Research;
