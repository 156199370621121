import { Component} from "react";
import { Markup } from "interweave";
import cautionRow from "./color-april-29-2020/caution-row.png";
import coronavirusExamples from "./color-april-29-2020/coronavirus-examples.png";
import fearRow from "./color-april-29-2020/fear-row.png";
import hateRow from "./color-april-29-2020/hate-row.png";
import pandemicExamples from "./color-april-29-2020/pandemic-examples.png";
import quarantineExamples from "./color-april-29-2020/quarantine-examples.png";
import shirts from "./color-april-29-2020/shirts.png";
import vaccineRow from "./color-april-29-2020/vaccine-row.png";
import medsciRow from "./color-april-29-2020/medsci-row.png";

//import medicalscience from "./Colorgrams/medicalscience.jpeg";
//import engineering from "./Colorgrams/engineering.jpeg";
//import neuroscience from "./Colorgrams/neuroscience.jpeg";
//import mathematics from "./Colorgrams/mathematics.jpeg";

//import vaccine from "./Colorgrams/medicalscience.jpeg";
//import health from "./Colorgrams/engineering.jpeg";
//import nurse from "./Colorgrams/neuroscience.jpeg";
//import medicalfacemask from "./Colorgrams/mathematics.jpeg";

//class MedSciRow extends EmbeddedGallery {
//  constructor() {
//    super();
//    this.images = [
//      {
//        original: {medicalscience}.medicalscience,
//        thumbnail: {medicalscience}.medicalscience,
//        description: "Medical Science",
//      },{
//        original: {engineering}.engineering,
//        thumbnail: {engineering}.engineering,
//        description: "Engineering",
//      },{
//        original: {neuroscience}.neuroscience,
//        thumbnail: {neuroscience}.neuroscience,
//        description: "Neuroscience"
//      },{
//        original: {mathematics}.mathematics,
//        thumbnail: {mathematics}.mathematics,
//        description: "Mathematics"
//      }
//    ]
//
//  }
//}

const AnalysisArticle1 = { 
"Title": "How Google Images Use Color to Frame the Pandemic",
"Authors": ["Douglas Guilbeault"],
"PublicationDate": "April 29th, 2020",
"Abstract": `<p>New research exposes hidden biases in how Google’s algorithm represents coronavirus and its emotional impact. Using <i>compsyn</i> – a novel image processing software – we show that Google images systematically use color to convey our shared emotions toward various concepts relating to the virus. Our software also provides novel insight into how the color of Google images reflects widespread gender biases in public perceptions of science amid the pandemic. While these results highlight the alarming ways in which the internet can track our thoughts and feelings in times of crisis, they also point to exciting new research directions for social psychology and algorithmic bias.</p>

<br/><div class='floatcenter'><img src=${vaccineRow}></div><br/> <br/> 
`,
"Body": `
<p>During a global crisis, access to information is vital. Social media companies have been touted as heroes amid coronavirus because of the information access they provide. Yet, the pandemic also reveals the dark side of our dependence on social media. False and biased information about the coronavirus has spread far and wide over major social media platforms such as <a target="_blank" href="https://about.fb.com/news/2020/04/covid-19-misinfo-update/">Facebook</a> and <a target="_blank" href="https://www.blog.google/outreach-initiatives/google-news-initiative/covid-19-65-million-help-fight-coronavirus-misinformation/">Google</a>, with dire consequences. Our research team (compsyn) has built a new <a href="https://github.com/comp-syn/comp-syn" target="_blank">computational framework</a> for tracking bias in the use of color in Google images to display core concepts relating to the pandemic.</p>

<br/><div class='floatcenter'><img src=${medsciRow}></div><br/> <br/> 

<p>Our software compsyn – published in <a href="https://www.sciencedirect.com/science/article/abs/pii/S0010027720301256" target="_blank">Cognition</a> – enables new ways of revealing algorithmic bias by showing how color is used in Google images to frame current issues and ideas in public discours. Compsyn generates what we call colorgams, which are averaged visual representations of the color distribution associated with concepts in Google search results. For instance, our recent study uses colorgrams and mathematical analyses to show that scientific research – including medical science – is strongly associated with blueish hues, which have been found to promote trust and authority across a range of psychological studies (see <a href="https://science.sciencemag.org/content/323/5918/1226" target="_blank">here</a>, <a href="https://link.springer.com/article/10.1007/s11747-010-0245-y" target="_blank">here</a>, and <a target="_blank" href="https://research.utwente.nl/en/publications/color-matters-color-as-trustworthiness-cue-in-web-sites">here</a>). All the colorgrams in this piece are generated from an IP address in New York City.</p>

<br/><div class='floatcenter'><img src=${vaccineRow}></div><br/> <br/> 

<p>Indeed, a number of terms at the intersection of science and coronavirus appear with a bluish skew, such as <i>vaccines</i>, <i>health</i>, <i>nurses</i>, and <i>medical face masks</i>. Surprisingly, we show that bluish hues also pervade Google images relating to the word <i>pandemic</i> specifically.</p> 

<br/> <br/><br/><div class='floatcenter'><img src=${pandemicExamples}></div> <br/> 

<p>Crucially, we must not forget that colors are highly gendered in the popular imagination, where blue is conventionally associated with males. The gendered nature of color can be readily seen by using compsyn to capture the average Google image corresponding to searches for “male shirt” and “female shirt”, where “male shirt” is distinctly blue and “female shirt” is pink.</p> 

<br/> <br/><br/><div class='floatcenter'><img src=${shirts}></div> <br/> 
<p>In our recent study, we discuss how the association of blue hues across the sciences is consistent with widespread <a href="https://academic.oup.com/qje/article-abstract/125/3/1101/1903648?redirectedFrom=PDF" target="_blank">gender disparity</a> in these scientific fields of research, which are predominantly male. While color may be a deceptively simple feature of online images, our research shows how analyzing color can provide a window into deep cultural and emotional trends in collective cognition.</p>


<br/><br/><div class='floatcenter'><img src=${fearRow}></div><br/> 

<p>Compsyn also uses color associations in Google images as a window into the emotional psychology provoked by the pandemic. We find that emotions commonly associated with the pandemic, including <i>fear</i>, <i>sadness</i>, and <i>isolation</i>, are represented by dark grey hues, very similar to those we find for the search term <i>death</i>. Our recent study elaborates on the psychological and cultural origins of these collective color associations. In this sense, Google images encode the hidden poetics that underlie our collective emotional state.</p> 

<br/><br/><br/><div class='floatcenter'><img src=${hateRow}></div><br/>

<p>A striking finding from our study is that emotionally provocative concepts, such as <i>hate</i>, <i>bad</i>, and <i>love</i>, are consistently associated with intensely red colors, consistent with research suggesting that red is one of the most emotionally arousing colors (see <a href="https://science.sciencemag.org/content/323/5918/1226" target="_blank">here</a> and <a href="https://www.nature.com/articles/435293a" target="_blank">here</a>). These associations underlie the conventional belief that red works especially well in public warnings signs to grab attention and trigger avoidant behavior, as in stop signs and red lights. Indeed, the colorgram for <i>alert</i> is captures a similarly red distribution.</p>

<br/> <br/><br/><div class='floatcenter'><img src=${coronavirusExamples}></div> <br/> 

<p>It is no surprise that we find a strikingly red skew in the Google images returned when for <i>coronavirus</i> itself – as a major ‘threat’ and ‘common enemy’ to humanity. Importantly, this red skew is driven not only by conventional uses of red associated with alert messages. Red hues pervade the aesthetics of coronavirus, appearing widely as the color used to visualize molecules and maps, as well as in the choice for general background imagery.</p>
<br/> <br/><br/><div class='floatcenter'><img src=${cautionRow}></div> <br/> 
<p>Meanwhile, compsyn allows us to reveal significant progress in the use of color to warn Google users about the dangers of coronavirus. Yellowish hues are conventionally tied to warning signs of all kinds, and we find that yellow is particularly prominent as a theme across images relating to the quarantine. Yellowish hues are tightly associated with safety in the U.S., such that associating it with quarantining may help to heighten the sense of necessity for precautions relating to coronavirus. Particularly interesting is our finding that yellowish hues have encroached on the digital representation of quarantine well beyond conventional signage, reaching further not only into protective clothing, but also into a plethora of visual metaphors and aesthetic choices in the design of background imagery.</p> 

<br/> <br/><br/><div class='floatcenter'><img src=${quarantineExamples}></div> <br/> 

<p>These color associations reveal important ways in which people design online images to communicate effectively in times of crisis. At the same time, these color associations highlight the alarming ways in which the internet can track our thoughts and feelings in times of crisis. Most concerningly, these color associations reveal the extent to which our consumption of information online is heavily framed along various emotional dimensions that can influence our perceptions.</p>

<br/> 
<p>Indeed, a number of governments have started to use color as a subtle tool of social control. For example, both <a href="https://psychcentral.com/blog/can-blue-colored-light-prevent-suicide/" target="_blank">Scotland and Japan</a> have installed blue light street lamps in the effort to reduce crime and suicide in public space, though the efficacy of the intervention have yet to be determined. At an even broader scale, innumerable advertising campaigns have made intentional use of color to bias people’s perceptions of products – for instance, in the use of blue to increase perceptions of trust toward online websites (<a href="https://link.springer.com/article/10.1007/s11747-010-0245-y" target="_blank">here</a> and <a href="https://research.utwente.nl/en/publications/color-matters-color-as-trustworthiness-cue-in-web-sites">here</a>), or in the use of red to increase attention and arousal in online messaging (<a href="https://science.sciencemag.org/content/323/5918/1226" target="_blank">here</a>). The recently released compsyn python package provides a novel way to track online images around the world, providing new insight into the hidden psychological dimensions of color influencing our public perceptions.</p>`
}

class CopyToggle extends Component {
  constructor(props) {
    super(props);
    this.handleExpandToggle = this.handleExpandToggle.bind(this);
    this.state = {
      expanded: true
    }
  }

  handleExpandToggle() {
    this.setState({expanded: !this.state.expanded});
  }

  render() {
    return (
      <div>
        <div className="hoverdiv" onClick={this.handleExpandToggle}>
        <h2>{this.props.Title}</h2>
        </div>
        <br/>
        <br/>
        <p>{this.props.PublicationDate}</p>
        <div className="floatright">
          <p>{this.props.Authors}</p>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        {
          this.state.expanded &&
          (
            <div>
            <Markup content={this.props.Body}/>
            </div>
          )
        }
        {
          this.state.expanded ||
          (<div className="hoverdiv" onClick={this.handleExpandToggle}>
          <Markup content={this.props.Abstract}/>
          </div>)
        }
      </div>
    )
  }
}



class AnalysisArticleApril2020 extends Component {
  render() {
    return (
      <CopyToggle
        Title={AnalysisArticle1.Title}
        Authors={AnalysisArticle1.Authors}
        Abstract={AnalysisArticle1.Abstract}
        PublicationDate={AnalysisArticle1.PublicationDate}
        Body={AnalysisArticle1.Body}
      />
    )
  }
}

export default CopyToggle;
export { AnalysisArticleApril2020 };
