import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ImageGallery from 'react-image-gallery';

import {init as clothsimInit, animate as clothsimAnimate, clearThree as clothsimClearThree} from "./clothsim.js";

import cstree from "./treeoflifeanddeathhisat.m4v"
import cstreefloor from "./treeoflifeanddeathhisat-floor.m4v"
import "./App.css";
import digitalsand1 from "./digitalsand1.png";
import backgroundtexture from "./linkhover.png";
import startsvid from "./startsvid.mp4";

class Gallery extends Component {
  componentDidMount(){
    document.body.style.backgroundImage = null;
  }
  
  componentWillUnmount(){
    document.body.style.backgroundImage = null;
  }

  render() {
    return (
      <div>
        <div className="content">
          <br/>
          <div className="copy">
          <video width="100%" controls>
            <source src={startsvid} type="video/mp4"/>
          </video>
          </div>
          <br/>
          <div className="copy" id="portfolio">
            <NavLink to="/gallery/portfolio">
              <div className="whitetext">
                  <h2>Portfolio</h2>
      {/*
                  <p><a href={portfolio}>View PDF Portfolio</a></p>
      */}
              </div>
            </NavLink>
          </div>
          <br/>
          <div className="copy" id="fivetastes">
            <NavLink to="/gallery/fivetastes">
              <div className="whitetext">
                <h2>Colorgrams of the 5 Tastes</h2>
              </div>
            </NavLink>
          </div>
          <br/>
          <div className="copy" id="flowers">
            <NavLink to="/gallery/Flowers">
              <div className="whitetext">
                <h2>16 Flowers</h2>
              </div>
            </NavLink>
          </div>
          <br/>
          <div className="copy" id="milleniaasmoment">
            <NavLink to="/gallery/milleniaasmoment">
              <div className="whitetext">
                <h2>Millenia As Moment</h2>
              </div>
            </NavLink>
          </div>
          <br/>
          <div className="copy" id="threelangips">
            <NavLink to="/gallery/threelangips">
              <div className="whitetext">
                <h2>Serene, Restfulness and Pleasant Lang-IP Grids</h2>
      {/* 
                <p>Keywords searched in 5 languages, from 5 IP geolocations.</p>
      */}
              </div>
            </NavLink>
          </div>
          <br/>
          <div className="copy" id="changingviews">
            <NavLink to="/gallery/changingviewsindatascienceoverfiftyyears">
              <div className="whitetext">
                <h2>Changing Views in Data Science over Fifty Years</h2>
      {/*
                <p>
                Featured in Stanford University Art of Science 2020
                </p>
      */}
              </div>
            </NavLink>
          </div>
          <br/>
          <div className="copy" id="treeoflifeanddeath">
            <NavLink to="/gallery/treeoflifeanddeath">
              <div className="whitetext">
                <h2>Tree of Life and Death</h2>
      {/*
                <p>Evolution of colorgram space between related concepts. (in 3d)</p>
      */}
              </div>
            </NavLink>
          </div>
          <br/>
          <div className="copy" id="dickinsonthenshakespeare">
            <NavLink to="/gallery/dickinsonthenshakespeare">
              <div className="whitetext">
                <h2>Colorgram Arrays of Well-Known Authors</h2>
      {/*
                <p>Created from the words of Dickinson and Shakespeare</p>
      */}
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}

// need to integrate three js into these two methods more thoroughly to facilitate scene cleanup
class GalleryTree extends Component {

  componentWillUnmount(){
    clothsimClearThree();
    document.getElementById("portal").style.display = "block";
    document.getElementById("footer").style.display = "block";
  }

  componentDidMount() {
    document.getElementById("footer").style.display = "none";
    document.getElementById("video").style.display = "none";
    document.getElementById("floorvideo").style.display = "none";
    document.getElementById("portal").style.display = "none";
    clothsimInit();
    clothsimAnimate( 0 );
  }

  render() {
    return (
      <div>
        <div className="floattop"><NavLink to="/gallery">←</NavLink></div>
        <video id="video" playsInline muted loop autoPlay width="320" height="240" src={cstree}></video>
        <video id="floorvideo" playsInline muted loop autoPlay width="320" height="240" src={cstreefloor}></video>
      </div>
    )
  }
}

class GalleryDigitalSand extends Component {

  componentWillUnmount(){
    document.getElementById("portal").style.display = "block";
  }

  componentDidMount() {
    document.getElementById("portal").style.display = "none";
  }
  render() {
    return (
      <div>
        <div className="background-color-white"></div>
        <div className="floattop"><NavLink to="/gallery">←</NavLink></div>
        <div className="imagefill">
        <img src={digitalsand1} alt=""/>
        </div>
      </div>
    )
  }
}


export default Gallery;
export { GalleryTree, GalleryDigitalSand};
