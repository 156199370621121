import React, { Component } from "react";
import PropTypes from 'prop-types';
import { css } from "@emotion/react";
import CircleLoader from "react-spinners/CircleLoader";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {OPTIONS, ListboxComponent} from "./AvailableTerms.js";

const spinnerCSS = {
  right: "4em",
  bottom: "2em"
}

class Colorgram extends Component {
  constructor(props) {
    super(props);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleViewCompositeImages = this.handleViewCompositeImages.bind(this);
    this.handleHideCompositeImages = this.handleHideCompositeImages.bind(this);
    this.state = {
      isHovering: false,
      open: false,
      lastError: "",
      color: "gray",
      showCompositeImages: false,
      compositeImageURLs: [],
      loading: false
    }
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }


  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  handleClick() {
    console.log(`expand modal for ${this.props.searchTerm}`);
    this.setState({open: true});
    this.setState({isHovering: false});
  }

  handleClose() {
    console.log(`close modal for ${this.props.searchTerm}`);
    this.setState({open: false})
    this.setState({isHovering: false});
  }


  handleViewCompositeImages() {
    console.log(`load composite images for ${this.props.searchTerm}`);
    this.setState({showCompositeImages: true});
    if (this.state.compositeImageURLs.length === 0) {
      this.setState({loading: true})
      var client = new WebSocket('wss://comp-syn.ialcloud.xyz/data');

      const request = JSON.stringify({
        action: "list_image_urls",
        filter: [{"terms": {"image_id": this.props.compositeImageIds}}]
      })

      client.onopen = () => {
        this.setState({loading: true});
        console.log(request);
        client.send(request);
      };

      client.onmessage = (message) => {
        this.setState({loading: true});
        const dataFromServer = JSON.parse(message.data);
        this.setState({lastResponse: dataFromServer.status});
        if (dataFromServer.status != 200) {
            const msg = dataFromServer.message + " '" + dataFromServer.query + "'";
            this.setState({lastError: msg});
        } else {
            this.setState({lastError: ""});
        }
        console.log(dataFromServer);
        this.setState({compositeImageURLs: dataFromServer.image_urls})
        this.setState({loading: false});
      };
      this.setState({loading: false})
    }
  }

  handleHideCompositeImages() {
    this.setState({showCompositeImages: false})
  }

  render() {
    return (
      <div>
        <div onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseHover} onClick={this.handleClick}>
          <img className={`${this.state.isHovering ? "hovered" : ""}`} src={this.props.src}/>
          {
            this.state.isHovering &&
            <div className="texthover labeltext">
            <p>{this.props.searchTerm}</p>
            </div>
          }
        </div>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
        >
        <div className="papermodal">
          <h2 id="simple-modal-title">{this.props.searchTerm}</h2>
          <div className="floatright">
            <CircleLoader color={this.state.color} loading={this.state.loading} css={spinnerCSS} size="50px" />
            <div style={{color: "gray"}}>{this.state.lastError}</div>
          </div>
          <img className="imagefill" src={this.props.src}/>
          <p id="simple-modal-description">
          {
            this.state.showCompositeImages ||
            <Button variant="contained" onClick={this.handleViewCompositeImages}>View Composite Images</Button>
          }
          {
            this.state.showCompositeImages &&
            <Button variant="contained" onClick={this.handleHideCompositeImages}>Hide Composite Images</Button>
          }
          <br/>
          <br/>
          <br/>
          {
            this.state.showCompositeImages &&
            this.state.compositeImageURLs.map(function(imageUrl, index) {
              return <div className="scrollinggallery">
                <img src={imageUrl} onError={i => i.target.style.display='none'}/>
              </div>
            })
          }
          </p>
        </div>
        </Modal>
      </div>
    );
  }
}


class Search extends Component {


  componentDidMount(){
     window.location.href = "https://api.comp-syn.com";
  }

  render() {
    return (
      <div>
      </div>
    );
  }
}

export default Search;
