import React, { Component } from "react";
import { Markup } from "interweave";

class TitleAbstractBody extends Component {
  constructor(props) {
    super(props);
    this.handleExpandToggle = this.handleExpandToggle.bind(this);
    this.state = {
      expanded: false
    }
    this.myDivToFocus = React.createRef()

  }

  handleExpandToggle() {
    this.setState({expanded: !this.state.expanded});
    if(this.myDivToFocus.current){
      this.myDivToFocus.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
  }

  render() {
    return (
      <div ref={this.myDivToFocus}>
        <div className="hoverdiv" onClick={this.handleExpandToggle}>
          <h3>{this.props.Title}</h3>
        </div>
        {
          this.state.expanded &&
          (
            <div>
            <div className="hoverdiv" onClick={this.handleExpandToggle}>
            <Markup content={this.props.Abstract}/>
            <br/><div><a href={this.ref}><i>Read less...</i></a></div>
            </div>
            <Markup content={this.props.Body}/>
            </div>
          )
        }
        {
          this.state.expanded ||
          (<div className="hoverdiv" onClick={this.handleExpandToggle}>
          <Markup content={this.props.Abstract}/>
          <br/><div><a href={this.ref}><i>Read more...</i></a></div>
          </div>)
        }
      </div>
    )
  }
}

export default TitleAbstractBody;
