import React, { Component } from "react";
import { NavLink} from "react-router-dom";
import "./App.css";
import doug from "./Doug.jpg";
import ethan from "./Nadler_Photo.jpg";
import mark from "./Mark_Chu.jpg";
import elise from "./Elise.jpeg";
import bhargav from "./bhargav.jpg";
import aabir from "./Aabir.png";
import tasker from "./tasker.jpg";
import ruggiero from "./ruggiero.png";
import christian from "./christian.jpg";
 
class Team extends Component {
  render() {
    return (
      <div>
        <div className="content">
          <div className="copy">
            <img className="portrait" src={doug}/>
            <mark>Douglas Guilbeault</mark> is an Assistant Professor at the Haas School of Business in the University of California, Berkeley. His work explores the intersection of computational sociology and cognitive science, with a focus on how social groups construct new ideas and categories. His publications have appeared in top journals, including Nature Communications, The Proceedings of the National Academy of the Sciences, Cognition, and Policy & Internet. His journalism has also appeared in prominent venues, including the Atlantic and Wired. As a member of comp-syn, Guilbeault explores automated approaches to measuring aesthetic features of communication that enrich our theories of culture and cognition, and thereby support a broader computational science of cultural poetics.
          </div>
          <br/>
          <div className="copy">
            <img className="portrait" src={ethan}/>
            <mark>Ethan Nadler</mark> is a Postdoctoral Research Fellow at Carnegie Observatories and USC and received his Ph.D. in Physics from Stanford University. His research combines cosmological simulations, astrophysical observations, and particle physics to deliver new insights into dark matter. By drawing on techniques from physics and statistics, he addresses problems at the interface of complex systems and data science with a unique set of analytic and numerical tools. His other areas of interest and expertise include data visualization, aesthetics, and philosophy of science.
          </div>
          <br/>
          <div className="copy">
            <img className="portrait" src={mark}/>
            <mark>Mark Chu</mark> is an Australian artist who has exhibited work in New York City, Atlantic City, Shanghai, Singapore, and Melbourne. His paintings employ heightened color, abstract and heightened texture to enhance the viewer’s psychological experience. Chu has enjoyed portrait sittings with luminaries such as French DJ Laurent Garnier and graffiti trailblazer Tom Gerrard. Past Chairman of the National Gallery of Australia Rupert Myer AO noted that Chu’s works reflect aspects of the human condition. Chu is committed to a multidisciplinary practice, and makes ongoing contributions to scientific research. He is a past reviewer for The Good Food Guide and has recorded as a solo pianist with the Melbourne Symphony Orchestra.
          </div>
          <br/>
          <div className="copy">
            <img className="portrait" src={elise}/>
            <mark>Elise Darragh-Ford</mark> is a second-year Physics PhD Student at Stanford. Raised by theatre artists in the Bay Area, she has long been interested in both art and science and the places where they intersect. As a graduate student, she works on applying new machine learning and statistical methods to understanding the small-scale structure of galaxies and its implications for models of dark matter. However, she is very excited to be extending these tools outside of astrophysics and into new realms of meaning.
          </div>
          <br/>
          <div className="copy">
            <img className="portrait" src={bhargav}/>
            <mark>Bhargav Srinivasa Desikan</mark> is a Research Fellow at the Knowledge Lab, and also works as a Teaching and Instructional Assistant at the University of Chicago’s Computational Social Sciences program. He works at the intersection of Machine Learning and the Social Sciences, is currently co-writing a book titled ‘Thinking with Deep Learning’ and has previously written a book on Natural Language Processing and Computational Linguistics in Python as well as having co-authored articles published in the Journal of Machine Learning Research and in Cognition. He has spoken at over a dozen Python conferences across Europe, Asia and North America, is a maintainer of two Python Scientific Computing packages, and contributor to multiple other open source python libraries. In his spare time, he enjoys bouldering, biking, cooking Indian food, spending time with his cat, and dancing to Techno and House music with friends.
          </div>
          <br/>
          <div className="copy">
            <img className="portrait" src={aabir}/>
            <mark>Aabir Abubaker Kar</mark> is a data scientist and computational social scientist working as a Teaching and Research Assistant at the University of Chicago. With training in mechanical engineering (particularly supply chain dynamics), physics, and computational social science, he is drawn to projects that leverage data in interesting and impactful ways. For his Master’s thesis, he is interested in digital representations of social forms of knowledge - particularly bias and polarization. He works closely with machine learning and artificial intelligence with a focus on practical applications - past work includes audio processing for obstacle recognition, natural language processing for paraphrase identification, credibility score assessment for fake news, and unsupervised feature learning for image and time-series data. He has written tens of thousands of lines of Python code - but please please don’t ask him to code in R.
          </div>
          <br/>
          <div className="copy">
            <img className="portrait" src={tasker}/>
            <mark>Tasker Hull</mark> is a software developer based in Toronto, Canada. With a background in molecular biology, he often looks to nature for inspiration when building reliable software systems. During the other half of the day he performs regularly as a musician throughout Canada, and is featured on numerous recordings as a producer and performer.
          </div>
          <br/>
          <div className="copy">
            <img className="portrait" src={ruggiero}/>
            <mark>D. Ruggiero Lo Sardo</mark> is a Researcher at Sapienza Università di Roma. His research explores complexity science and its  interdisciplinary applications ranging from the resilience of the health-care system to measures of complexity in the writing process. Within the comp-syn team, his musings probe the interface between information theory, meaning and language.
          </div>
          <br/>
          <div className="copy">
            <img className="portrait" src={christian}/>
            <mark>Christian Conway</mark> is a California based musician exploring methods of intuitively representing complex data through music. His work combines electronic music with simulations of physical systems to create genre-fluid work that explores the informational capacities of sound. Christian has presented Brain Controlled Instruments at the Drescher Artistic Residency, performed improvisational works at Burning Man, and sailed to nearly every island off the coast of California. He is now working on research in auditory primitives and algorithmic methods for utilizing neural oscillations for musical performance.
          </div>
          <br/>
        </div>
      </div>
    );
  }
}
 
export default Team;
