import React, { Component } from "react";
 
class Contact extends Component {
  render() {
    return (
      <div>
        <div className="content">
          <div className="copy">
            <p>
              group@comp-syn.com
              <br/><br/>
              <a href="https://github.com/comp-syn">https://github.com/comp-syn</a>
              <br/><br/>
              <a href="https://www.instagram.com/computationalsynesthesia">https://instagram.com/computationalsynesthesia</a>
              <br/><br/>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
 
export default Contact;
