import React, { Component } from "react";
//import { NavLink} from "react-router-dom";
import { AnalysisArticleApril2020 } from "./Analysis/Copy"; 
import "./App.css";

 
class Analysis extends Component {
  render() {
    return (
      <div>
        <div className="content">
          <div className="copy">
            <AnalysisArticleApril2020/>
          </div>
        </div>
      </div>
    );
  }
}
 
export default Analysis;
