import React, { Component } from "react";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Home from "./Home";
import Research from "./Research";
import Contact from "./Contact";
import Gallery from "./Gallery";
import Analysis from "./Analysis";
import { 
  GalleryTree, 
  GalleryDigitalSand,
} from "./Gallery";
import Search from "./Search";

import {
  GalleryPortfolio,
  GalleryFiveTastes,
  GalleryThreeLangips,
  GalleryDickinsonThenShakespeare,
  GalleryArtOfScience2020,
  GalleryFlowers,
  GalleryMilleniaAsMoment
} from "./NiceGallery";

import blacklogo from "./marklogo-blob-fire.gif";

import PrivacyPolicy from "./PrivacyPolicy.js";
import Code from "./Code.js";
import Publications from "./Publications.js";
import ColorTheory from "./ColorTheory.js";
import CollectiveCognition from "./CollectiveCognition.js";
import AlgorithmicBias from "./AlgorithmicBias.js";
import ComputationalAesthetics from "./ComputationalAesthetics.js";
import Team from "./Team.js";
 

function toggleResponsiveMenuOff() {

    console.log("toggling menu off");
    var x = document.getElementById("business-navigation");
    x.className = "nav";

}

function  toggleResponsiveMenu() {
    console.log("toggling menu");
    var x = document.getElementById("business-navigation");
    if (x.className === "nav") {
      x.className += " responsive";
    } else {
      toggleResponsiveMenuOff(); 
    }
  }


class Main extends Component {

  render() {
    return (
      <HashRouter>
        <div>
          <div className="portal" id="portal">
            <div id="business-navigation" className="nav">
              <ul>
                <li><NavLink exact activeClassName="active" onClick={() => toggleResponsiveMenuOff()} to="/team">Team</NavLink></li>
                <li><NavLink exact activeClassName="active" onClick={() => toggleResponsiveMenuOff()} to="/contact">Contact</NavLink></li>
                <li><NavLink exact activeClassName="active" onClick={() => toggleResponsiveMenuOff()} to="/gallery">Gallery</NavLink></li>
                <li><NavLink exact activeClassName="active" onClick={() => toggleResponsiveMenuOff()} to="/analysis">Analysis</NavLink></li>
                <li><NavLink exact activeClassName="active" onClick={() => toggleResponsiveMenuOff()} to="/code">Code</NavLink></li>
                <li><NavLink exact activeClassName="active" onClick={() => toggleResponsiveMenuOff()} to="/publications">Publications</NavLink></li>
                <li><NavLink exact activeClassName="active" onClick={() => toggleResponsiveMenuOff()} to="/research">Research</NavLink></li>
              <div className="hamburger">
                <button className="darkbutton" onClick={() => toggleResponsiveMenu()}>⁝</button>
              </div>
              </ul>
            </div>
            <ul>
            <li><NavLink exact activeClassName="none" to="/"><img src={blacklogo} alt="logo"/></NavLink></li>
            </ul>
          </div>
            <Route exact path="/" component={Home}/>
            <Route exact path="/gallery" component={Gallery}/>
            <Route exact path="/gallery/treeoflifeanddeath" component={GalleryTree}/>
            <Route exact path="/gallery/changingviewsindatascienceoverfiftyyears" component={GalleryArtOfScience2020}/>
            <Route exact path="/gallery/digitalsand" component={GalleryDigitalSand}/>
            <Route exact path="/gallery/portfolio" component={GalleryPortfolio}/>
            <Route exact path="/gallery/threelangips" component={GalleryThreeLangips}/>
            <Route exact path="/gallery/fivetastes" component={GalleryFiveTastes}/>
            <Route exact path="/gallery/flowers" component={GalleryFlowers}/>
            <Route exact path="/gallery/dickinsonthenshakespeare" component={GalleryDickinsonThenShakespeare}/>
            <Route exact path="/gallery/milleniaasmoment" component={GalleryMilleniaAsMoment}/>
            <Route path="/research" component={Research}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/publications" component={Publications}/>
            <Route path="/search" component={Search}/>
            <Route path="/code" component={Code}/>
            <Route path="/team" component={Team}/>
            <Route path="/analysis" component={Analysis}/>
            <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
          <div id="footer" className="footer">
          <br/>
          <br/>
          <br/>
            <NavLink exact activeClassName="active" onClick={() => toggleResponsiveMenuOff()} to="/privacy-policy">privacy policy</NavLink>
          </div>
        </div>
      </HashRouter>
    );
  }
}

// <NavLink exact activeClassName="active" onClick={() => toggleResponsiveMenuOff()} to="/privacy-policy">Privacy Policy</NavLink>

export default Main;
