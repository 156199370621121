import React, { Component } from "react";
import collectivecognitionthumb from "./collectivecognition.svg";

class CollectiveCognitionAbstract extends Component {
  render() {
    return (
      <div className="copy">
        <h2 id="collectiv-cognition">Collective Cognition</h2>
        <img src={collectivecognitionthumb} className="colorgram" alt="collective cognition"/>
Kynes turned a hard stare at the boy, brought his attention back to the 
Duke. "I'd remember to protect the integrity of my stillsuit. If I were outside 
the worm zone or in rock, I'd stay with the ship. If I were down in open sand, 
I'd get away from the ship as fast as I could. About a thousand meters would be 
far enough. Then I'd hide beneath my robe. A worm would get the ship, but it 
might miss me."
      </div>
    );
  }
}


class CollectiveCognition extends Component {
  render() {
    return (
      <div className="content">
      <CollectiveCognitionAbstract/>
      <div className="copy">
      even more copy about this topic
      </div>
      </div>
    );
  }
}
 
export default CollectiveCognition;
export { CollectiveCognitionAbstract };
