import React, { Component } from "react";

import multimodalfig from "./multimodalfig.png";
import colortheoryfig from "./colortheoryfig.png";
import colortheorythumbnail from "./colortheorythumbnail.png";
import culturalpoeticsfig from "./culturalpoeticsfig.png";
import culturalpoeticsthumbnail from "./culturalpoeticsthumbnail.png";
import algorithmicbiasfig from "./algorithmicbiasfig.png";


const MissionStatement = `
<p><mark>Comp-syn</mark> is an analytics collective consisting of physicists, computer scientists, social scientists, cognitive scientists, software developers, and artists. Comp-syn is unified by the goal of quantifying and understanding aspects of human culture, with a focus on aesthetic computation---that is, processes of computation enabled by poetic devices in images and text. Comp-syn investigates aesthetic computation using diverse techniques drawn from statistics, data science, machine learning, online experiments, and art. Comp-syn’s research is published in top interdisciplinary journals, including Cognition and The Proceedings of the International Conference of Computational Linguistics. Comp-syn actively promotes open science; our software is publicly distributed via the Python package comp-syn. As well, Comp-syn's artistic works are collected in prestigious galleries, including as a winner of Stanford University’s “Art of Science” competition for the work “Changing Views in Data Science over 50 Years.”</p>

<p>Comp-syn is currently exploring the frontiers of computer vision, color theory, algorithmic bias, and the emergence of human semantics. Each of these pursuits is associated with corresponding artistic projects. Please contact our team if you are interested in collaborating on or investing in any of these scientific and artistic endeavors.</p>
`

const ResearchSummaries = [
{
"Title": "Multimodal Semantics",
"Abstract": `<i><img class="thumbnail" src=${multimodalfig}><br/><br/>What do traditional machine learning algorithms lack by learning in isolated domains, and how can multimodal aspects of meaning be computed?</i>`,
"Body": `
<p>Think of a spoon. What you imagine is likely imbued with sensory data, including visible cues such as size, shape, tactile information, as well as more subjective dimensions including what it’s like to hold a spoon or eating food from a spoon. This multimodal mixture influences how the mind harnesses sensory information and experience to abstract meaning. Intriguingly, functional and behavioral experiments in neuroscience and psychology lend credence to the idea that cognition is embodied---for example, the brain is known to simulate sensory input as part of its representation of concrete concepts like spoon or dog.</p>

<p>Meanwhile, popular machine learning and artificial intelligence algorithms largely operate in limited domains. Most modern image classification algorithms are only trained to recognize image input, while most natural language processing networks have only ever “seen” text. These approaches are remarkably successful at specific tasks, but they cannot be said to capture human cognition at a fundamental  level without forming multimodal connections.</p>

<p>Comp-syn pushes the boundaries of multimodal computation to quantitatively capture meaning. In <a href="/#/publications">comp-syn: Perceptually Grounded Word Embeddings with Color</a>, we linked a massive corpus of English words to their associated color distributions. These “color vectors” are distributed in a state-of-the-art Python package that is designed to integrate seamlessly with standard computational and machine learning routines. We are actively incorporating complementary sensory input including image texture and audio data to create a multimodal playground for exploring computational semantics. Comp-syn envisions a future in which the computational representation of spoon synthesizes a rich, diverse set of associations that reflect human cognition.</p>
  `
}, {
  "Title": "Color Theory",
  "Abstract": `<i><img class="thumbnail" src=${colortheorythumbnail}><br/><br/>How does the nature of human visual perception inform mathematical descriptions of colorspace, and how does this shape the use of color to represent abstract concepts?</i>`,
  "Body": `
<p>Humans perceive color based on the response of photoreceptor cells in the retina to the wavelengths of light emitted from physical objects. These physical and biological origins underpin subtle relationships between color and psychology, mathematics, and philosophy. For example, color is known to prime emotional responses in studies of human behavior. Meanwhile, the formal mathematical description of colorspace has captured the interest of physicists for centuries, and the abstract, affective nature of color plays a key role in philosophical debates on the physical nature of reality.</p>

<p>Comp-syn studies color through an interdisciplinary lens that combines all of these perspectives with modern computational tools. The comp-syn software transforms standard color representations of images to a perceptually uniform colorspace that is carefully designed to reflect human color vision. By analyzing the perceptually uniform color distributions of images en masse, Comp-syn pushes the boundaries of current image processing techniques while staying grounded to physical and biological constraints.</p>

<p>By aggregating images at scale, often drawing from massive crowd-sourced databases, Comp-syn studies how color represents language in both natural and digital images. <a href="/#/publications">Color Associations in Abstract Semantic Domains</a> demonstrates that color pervades Google Image search results associated with abstract concepts including academic disciplines, music genres, and emotions. These findings open up a new field of exploration at the multimodal boundary of color and abstraction, art, music, and psychology.</p>

<p>Beyond the distribution of color in images, Comp-syn is investigating the spatial correlation between color and pixel intensity. This property of images, colloquially referred to as texture, appears to drive the complex behavior of computer vision algorithms that have been claimed to capture aspects of human visual processing. Paradoxically, these same algorithms do not utilize color in a meaningful way. Comp-syn is developing a new, perceptually grounded method based on the discrete wavelet transform that retains the natural correlation between color and texture and will inform computer vision algorithms.</p>
  `
}, {
  "Title": "Cultural Poetics",
  "Abstract": `<i><img class='thumbnail' src=${culturalpoeticsthumbnail}><br/><br/>Why are analogy and meaning-making inextricably linked to perception, and how do new forms of metaphor, poetry, and art emerge in social contexts?</i>`,
  "Body": `
<p>Whether it's identifying newly discovered galaxies or marketing radical innovations in technology and art, society is faced with an inescapable categorization problem: how do we make sense of new and ambiguous phenomena? This problem becomes all the more challenging when considering that the frontiers of human understanding involve entities that we can’t readily observe with our senses, such as dark matter or social networks. How do people ‘make sense’ of phenomena they can’t sense? They do so, in large part, through cultural poetics: by harnessing what they already know, on the basis of everyday experiences and social life, people generate sensory-based metaphors that illuminate the conceptual trails that guide human thinking into the unknown. Yet, despite the widely recognized importance of analogy-making in human creativity and discovery, analogies remain notoriously difficult to automatically detect and represent mathematically. Comp-syn develops key innovations in this space.</p>

<p>Comp-syn explores the computational nature of analogy by developing machine learning algorithms that detect and generate poetic associations between sensory information --- such as color and texture --- with linguistic concepts, such as academic disciplines and music genres. Beyond the semantic color associations presented in our first paper, <a href="/#/publications">Color Associations in Abstract Semantic Domains</a>, Comp-syn has linked 40,000 of the most popular English words to their associated color distributions in Google Images. Strikingly, these color associations are a powerful predictor of concept abstractness, rated according to human judgments. Crucially, these color-based representations of words also substantially improve the automated detection of linguistic metaphors, while revealing surprising new insights into the nature of metaphor itself, such as the finding that metaphorical pairs (e.g. ‘strong focus’) are much closer in colorspace than literal pairs (e.g. ‘strong muscle’). These findings hint that metaphorical reasoning may leverage color to forge conceptual links between otherwise distinct ideas.</p>

<p>Comp-syn’s explorations into the multimodal, sensory architecture of human analogies in cognition and culture are just beginning. Ongoing projects build upon color-based analogies to incorporate additional computational dimensions, such as texture and sound, which promise to reveal new insights into the mathematical and perceptual nature of abstraction, and thus into the nature of creativity and innovation at the forefront of human understanding. In parallel, Comp-syn is exploring how these insights inform the emergence of meaning itself, both in everyday human interactions, and in more specialized domains, such as art, politics, economics, and science.</p>
  `
}, {
  "Title": "Algorithmic Bias",
  "Abstract": `<i><img class='thumbnail' src=${algorithmicbiasfig}><br/><br/>Do different media encode gender and racial biases in distinct ways, and how does the representation of marginalized groups at scale change geographically and in time?</i>`,
  "Body": `
<p>The study of multimodal semantics provides a new approach to the problem of algorithmic bias by revealing how different modalities – such as texts and images – differ in their representation of key social issues, including demographic discrimination and political debates. A popular intuition is that texts and images capture the same semantic information, such that bias is expected to be equally distributed across modalities. Yet, the vast majority of computational methods applied to the study of algorithmic bias focus almost exclusively on textual information. Comp-syn’s ongoing research reveals that social biases – such as biases against women – are more strongly encoded into online images than in online texts. These findings set the groundwork for Comp-syn’s larger-scale investigations into how images and texts are leveraged online to represent and communicate topics of key social importance, including political debates, disinformation, and discriminatory beliefs.</p>
  `
}
]
  
 
export { MissionStatement, ResearchSummaries };
