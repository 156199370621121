import React, { Component } from "react";
import { NavLink} from "react-router-dom";
import csbusinesscard from "./csbusinesscard.svg";
import cstree from "./treeoflifeanddeathhisat.gif";
import serioustile from "./serioustile.svg";
 
class Home extends Component {

  // Hide navigation bar at home page. Use ComponentDidMount as we need to wait for navbar to render before hiding it.
  componentDidMount(){
     document.getElementById("business-navigation").style.display = "none";
  }

  componentWillUnmount(){
     document.getElementById("business-navigation").style.display = "block";
  }

  render() {
    return (
      <div>
        <div className="background-color-white">
          <div className="gallery">
            <br/>
            <div className="mutetext">
            <h4>Computational Synesthesia</h4>
            </div>
            <br/>
            <div className="row">
              <div className="landingtile" >
                <NavLink to="/research">
                  <div className="imagehover">
                  <img src={serioustile} className="absolutecg" alt="comp-syn"/>
                  </div>
                </NavLink>
              </div>
              <div className="landingtile" >
                <NavLink to="/gallery">
                  <div className="imagehover">
                    <img src={cstree} className="absolutecg" alt="comp-syn"/>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default Home;
